define("liquid-fire/dsl", ["exports", "liquid-fire/animate", "liquid-fire/rule", "liquid-fire/constraint", "liquid-fire/action"], function (_exports, _animate, _rule, _constraint, _action) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DSL {
    constructor(map, constraints) {
      this.map = map;
      this.constraints = constraints;
    }
    setDefault(props) {
      (0, _animate.setDefaults)(props);
    }
    transition() {
      let rule = new _rule.default();
      let parts = Array.prototype.slice.apply(arguments).reduce(function (a, b) {
        return a.concat(b);
      }, []);
      for (let i = 0; i < parts.length; i++) {
        rule.add(parts[i]);
      }
      rule.validate(this.map);
      this.constraints.addRule(rule);
    }
    fromRoute(routeName) {
      return [new _constraint.default('oldRoute', routeName)];
    }
    toRoute(routeName) {
      return [new _constraint.default('newRoute', routeName)];
    }
    withinRoute(routeName) {
      return this.fromRoute(routeName).concat(this.toRoute(routeName));
    }
    fromValue(matcher) {
      return [new _constraint.default('oldValue', matcher)];
    }
    toValue(matcher) {
      return [new _constraint.default('newValue', matcher)];
    }
    betweenValues(matcher) {
      return this.fromValue(matcher).concat(this.toValue(matcher));
    }
    fromModel(matcher) {
      return [new _constraint.default('oldModel', matcher)];
    }
    toModel(matcher) {
      return [new _constraint.default('newModel', matcher)];
    }
    betweenModels(matcher) {
      return this.fromModel(matcher).concat(this.toModel(matcher));
    }
    hasClass(name) {
      return new _constraint.default('parentElementClass', name);
    }
    matchSelector(selector) {
      return new _constraint.default('parentElement', function (elt) {
        return elt.is(selector);
      });
    }
    childOf(selector) {
      return this.matchSelector(selector + ' > *');
    }
    use(nameOrHandler) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      return new _action.default(nameOrHandler, args);
    }
    reverse(nameOrHandler) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }
      return new _action.default(nameOrHandler, args, {
        reversed: true
      });
    }
    useAndReverse(nameOrHandler) {
      for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
        args[_key3 - 1] = arguments[_key3];
      }
      return [this.use(nameOrHandler, ...args), this.reverse(nameOrHandler, ...args)];
    }
    onInitialRender() {
      return new _constraint.default('firstTime', 'yes');
    }
    includingInitialRender() {
      return new _constraint.default('firstTime', ['yes', 'no']);
    }
    inHelper() {
      for (var _len4 = arguments.length, names = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        names[_key4] = arguments[_key4];
      }
      return new _constraint.default('helperName', names);
    }
    outletName() {
      for (var _len5 = arguments.length, names = new Array(_len5), _key5 = 0; _key5 < _len5; _key5++) {
        names[_key5] = arguments[_key5];
      }
      return new _constraint.default('outletName', names);
    }
    media(query) {
      return new _constraint.default('media', function () {
        return window.matchMedia(query).matches;
      });
    }
    debug() {
      return 'debug';
    }
  }
  _exports.default = DSL;
});