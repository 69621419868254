define("liquid-fire/transitions/fly-to", ["exports", "liquid-fire"], function (_exports, _liquidFire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = flyTo;
  function flyTo() {
    let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (!this.newElement) {
      return _liquidFire.Promise.resolve();
    } else if (!this.oldElement) {
      this.newElement.css({
        visibility: ''
      });
      return _liquidFire.Promise.resolve();
    }
    let oldOffset = this.oldElement.offset();
    let newOffset = this.newElement.offset();
    if (opts.movingSide === 'new') {
      let motion = {
        translateX: [0, oldOffset.left - newOffset.left],
        translateY: [0, oldOffset.top - newOffset.top],
        outerWidth: [this.newElement.outerWidth(), this.oldElement.outerWidth()],
        outerHeight: [this.newElement.outerHeight(), this.oldElement.outerHeight()]
      };
      this.oldElement.css({
        visibility: 'hidden'
      });
      return (0, _liquidFire.animate)(this.newElement, motion, opts);
    } else {
      let motion = {
        translateX: newOffset.left - oldOffset.left,
        translateY: newOffset.top - oldOffset.top,
        outerWidth: this.newElement.outerWidth(),
        outerHeight: this.newElement.outerHeight()
      };
      this.newElement.css({
        visibility: 'hidden'
      });
      return (0, _liquidFire.animate)(this.oldElement, motion, opts).then(() => {
        this.newElement.css({
          visibility: ''
        });
      });
    }
  }
});