define("liquid-fire/templates/components/liquid-if", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zIEMJ1+H",
    "block": "{\"symbols\":[\"container\",\"valueVersion\",\"valueVersion\",\"&else\",\"&default\"],\"statements\":[[6,[37,0],[[35,17]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n\"],[2,\"\\n\"],[6,[37,7],null,[[\"value\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\",\"class\"],[[30,[36,0],[[35,6],[30,[36,0],[[35,5],false,true],null],[30,[36,0],[[35,5],true,false],null]],null],[30,[36,4],null,[[\"helperName\"],[[35,3]]]],[35,2],[35,1],[27,[32,4]],[35,14]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,5,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[3]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,16],null,[[\"id\",\"class\",\"growDuration\",\"growPixelsPerSecond\",\"growEasing\",\"shrinkDelay\",\"growDelay\",\"enableGrowth\"],[[35,15],[35,14],[35,13],[35,12],[35,11],[35,10],[35,9],[35,8]]],[[\"default\"],[{\"statements\":[[6,[37,7],null,[[\"value\",\"notify\",\"matchContext\",\"use\",\"rules\",\"renderWhenFalse\"],[[30,[36,0],[[35,6],[30,[36,0],[[35,5],false,true],null],[30,[36,0],[[35,5],true,false],null]],null],[32,1],[30,[36,4],null,[[\"helperName\"],[[35,3]]]],[35,2],[35,1],[27,[32,4]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,5,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"rules\",\"use\",\"helperName\",\"hash\",\"predicate\",\"inverted\",\"liquid-versions\",\"enableGrowth\",\"growDelay\",\"shrinkDelay\",\"growEasing\",\"growPixelsPerSecond\",\"growDuration\",\"class\",\"containerId\",\"liquid-container\",\"containerless\"]}",
    "moduleName": "liquid-fire/templates/components/liquid-if.hbs"
  });
  _exports.default = _default;
});