define("liquid-fire/templates/components/liquid-spacer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9FC6AQkW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],null,[[\"didMeasure\"],[[30,[36,0],[[32,0],[32,0,[\"sizeChanged\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"liquid-measured\"]}",
    "moduleName": "liquid-fire/templates/components/liquid-spacer.hbs"
  });
  _exports.default = _default;
});