define("liquid-fire/ember-internals/get-outlet-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2o97UXEr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],[\"outletState\"],null]]]],\"hasEval\":false,\"upvars\":[\"-get-dynamic-var\"]}",
    "moduleName": "liquid-fire/ember-internals/get-outlet-state.hbs"
  });
  _exports.default = _default;
});